import { useEffect, useRef } from 'react';

import { Box } from '@/components/shared/layout/box';
import { Image } from '@/components/shared/media/image';
import { generateSplashPageImageSources } from '@/components/shared/media/image/generate-sources';
import { ImageProps } from '@/components/shared/media/image/Image.props';
import { CONVERT_DATE_BANGKOK } from '@/components/shared/utility/date';
import {
  clearBodyLockScroll,
  enableBodyLockScroll,
} from '@/components/shared/utility/lock-scroll';
import { styled } from '@/stitches.config';

export const SPLASH_AVAILABLE = () => {
  // July 28, 2024, at 00:00:01
  const START_TIME = new Date(2024, 6, 28, 0, 0, 1);

  // July 28, 2024, at 23:59:59
  const END_TIME = new Date(2024, 6, 28, 23, 59, 59);

  const TODAY = CONVERT_DATE_BANGKOK(new Date());

  if (START_TIME < TODAY && TODAY < END_TIME) {
    return true;
  }

  return false;
};

const SplashStyled = styled('section', {
  position: 'fixed',
  width: 'var(--vw)',
  height: 'var(--viewport-height)',
  left: 0,
  top: 0,
  bc: '$black',
  zIndex: 10000,
  '@supports (height: 100svh)': {
    height: '100svh',
  },
  '@supports (width: 100vw)': {
    width: '100vw',
  },
  pe: 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const Content = styled(Box, {
  position: 'relative',
  zIndex: 10000,
  '@landscape': {
    width: 'var(--vw)',
    height: 'var(--viewport-height)',
  },
  '@portrait': {
    aspectRatio: '662/1360',
    height: '80%',
    maxWidth: '90%',
  },
});

const TEXT = `
    ขอพระองค์ทรงพระเจริญ 
    เนื่องในโอกาสพระราชพิธีมหามงคล 
    เฉลิมพระชนมพรรษา ๖ รอบ 
    ๒๘ กรกฏาคม ๒๕๖๗ 
    ด้วยเกล้าด้วยกระหม่อม ขอเดชะ
    ข้าพระพุทธเจ้า คณะผู้บริหาร และพนักงาน
    บริษัท ตรีเพชรอีซูซุเซลส์ จำกัด และกลุ่มอีซูซุประเทศไทย
  `;

const LANDSCAPE_BG_IMG_URL: ImageProps = {
  src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt2831b737f0cd45f6/66a202877749f588903b1244/landscape-bg.jpg',
  alt: TEXT,
  width: 2560,
  height: 1440,
};
const LANDSCAPE_CONTENT_IMG_URL: ImageProps = {
  src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltaf61f9081fcb5828/66a207254a3601e35d7c069d/landscape-content.png',
  alt: TEXT,
  width: 1280,
  height: 1440,
};
const PORTRAIT_BG_IMG_URL: ImageProps = {
  src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt6ff9e4f8a70437d5/66a20287962501049db5124f/portrait-bg.jpg',
  alt: TEXT,
  width: 1920,
  height: 2560,
};
const PORTRAIT_CONTENT_IMG_URL: ImageProps = {
  src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltbc598853bda76b21/66a208e75c5264219626f6fe/portrait-content.png',
  alt: TEXT,
  width: 662,
  height: 1360,
};

interface SplashSpecialEventProps {
  onClose: () => void;
}
export const SplashSpecialEvent: React.FC<SplashSpecialEventProps> = ({
  onClose,
}) => {
  const SPLASH_REF = useRef(null);

  const disableScrolling = (e) => {
    if (e.cancelable) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const onCloseSplash = () => {
    clearBodyLockScroll();

    if (!SPLASH_REF.current) return;
    SPLASH_REF.current.removeEventListener('pointermove', disableScrolling, {
      passive: false,
    });
    SPLASH_REF.current.removeEventListener('touchmove', disableScrolling, {
      passive: false,
    });
    onClose();
  };

  useEffect(() => {
    if (!SPLASH_REF.current) return;
    enableBodyLockScroll();
    SPLASH_REF.current.addEventListener('pointermove', disableScrolling, {
      passive: false,
    });
    SPLASH_REF.current.addEventListener('touchmove', disableScrolling, {
      passive: false,
    });

    return () => {
      if (!SPLASH_REF.current) return;
      SPLASH_REF.current.removeEventListener('pointermove', disableScrolling, {
        passive: false,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
      SPLASH_REF.current.removeEventListener('touchmove', disableScrolling, {
        passive: false,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SplashStyled
      ref={SPLASH_REF}
      data-test="splash_home_button"
      onClick={onCloseSplash}
    >
      <Image
        priority
        src={PORTRAIT_BG_IMG_URL.src}
        alt={TEXT}
        width={PORTRAIT_BG_IMG_URL.width}
        height={PORTRAIT_BG_IMG_URL.height}
        layout="fill"
        sources={generateSplashPageImageSources({
          mobileSrc: PORTRAIT_BG_IMG_URL.src,
          desktopSrc: LANDSCAPE_BG_IMG_URL.src,
        })}
      />
      <Content>
        <Image
          priority
          src={PORTRAIT_CONTENT_IMG_URL.src}
          alt={TEXT}
          width={PORTRAIT_CONTENT_IMG_URL.width}
          height={PORTRAIT_CONTENT_IMG_URL.height}
          layout="fill"
          objectFit="contain"
          sources={[
            {
              srcset: LANDSCAPE_CONTENT_IMG_URL.src,
              media: { minWidth: 1440 },
              options: { width: 640 },
            },
            {
              srcset: LANDSCAPE_CONTENT_IMG_URL.src,
              media: {
                minWidth: 1024,
                maxWidth: 1439,
                orientation: 'landscape',
              },
              options: { width: 640 },
            },
            {
              srcset: LANDSCAPE_CONTENT_IMG_URL.src,
              media: {
                minWidth: 480,
                maxWidth: 1023,
                orientation: 'landscape',
              },
              options: { width: 576 },
            },
            {
              srcset: PORTRAIT_CONTENT_IMG_URL.src,
              media: { minWidth: 480, maxWidth: 1439, orientation: 'portrait' },
              options: { width: 331 },
            },
            {
              srcset: PORTRAIT_CONTENT_IMG_URL.src,
              media: { maxWidth: 479 },
              options: { width: 331 },
            },
          ]}
        />
      </Content>
    </SplashStyled>
  );
};
