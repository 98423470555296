import { Euro5HomeConceptProps } from '@/components/sections/euro-5/concept';
import { LcsProps } from '@/components/sections/euro-5/lcs';
import { IHomeLineUp } from '@/components/sections/euro-5/line-up';
import { TestimonialProps } from '@/components/sections/euro-5/testimonial';
import { IMY24HeroBanner } from '@/components/sections/my24/home/hero-banner';
import {
  MENU_EURO_3_LINE_UP,
  MENU_EURO_5_LINEU_UP,
  MENU_LCS_ILSC,
  MENU_LCS_ISUZU_CARE,
  MENU_LCS_ISUZU_CARE_2,
  MENU_LCS_LEASING,
  MENU_LCS_PARTS,
  MENU_LCS_PARTS_2,
  MENU_LCS_RBSC,
  MENU_LCS_SERVICE_CENTER,
  MENU_LCS_SERVICE_CENTER_2,
  MENU_LCS_TELETEC,
  MENU_LCS_TRUCK_2_HAND,
  MENU_LCS_TRUCK_SERVICE_LINE,
} from '@/contents/link';

export const BANNER_EURO3_DATA: IMY24HeroBanner = {
  isCloseSplash: true,
  dataTrack: 'cv-home',
  headline: {
    title: 'รถบรรทุกอีซูซุประเทศไทย ISUZU TRUCKS Thailand Official',
  },
  items: [
    {
      preTitle: 'THE ROCK GXZ/FXZ',
      title: 'TOUGH AS THE ROCK <span>ลุยทุกความสำเร็จ</span>',
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blte06cf8c0eba660f5/6311744114fe2914091546ca/thumbnail_VDO_the_rock_mobile.jpg',
        alt: 'THE ROCK GXZ/FXZ',
        width: 1080,
        height: 1920,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt8de878365c02b05d/6311742f14fe2914091546c6/thumbnail_VDO_the_rock_desktop.jpg',
        alt: 'THE ROCK GXZ/FXZ',
        width: 1920,
        height: 1080,
      },
      video: {
        src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt11fea4719f3b7b60/6628d71f33301da43e89288c/Website_8_sec_9x16-compressed.mp4',
      },
      videoDesktop: {
        src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltef1e9629c565b0cf/6628db0581c884974437fda7/gxz-16x9-comparessed.mp4',
      },
      buttons: [
        {
          label: 'รายละเอียดทางเทคนิค',
          url: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt54fe839d441c0c64/649d035013bff820c52ed698/spec-sheet-the-rock-all-2023.pdf',
          target: '_blank',
          icon: 'file-download',
          color: 'button-white',
        },
      ],
    },
    {
      preTitle: 'ELF',
      title: 'THE ONLY ONE ELF <span>เป็นหนึ่งทุกความสำเร็จ</span>',
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltd274b7f7cb442c5c/62a2e4a443918857196ec5a7/elf_vdo_m.png',
        alt: 'ELF',
        width: 1200,
        height: 1600,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt853f6a60b3685d40/62a2e4a9891af05acaff07a9/elf_vdo_d.png',
        alt: 'ELF',
        width: 2048,
        height: 1024,
      },
      video: {
        src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt9088e69e43a2bf7f/6628d849fb977c9ab436b28c/elf-8-secs-1080-1920_20220606_comparessed.mp4',
      },
      videoDesktop: {
        src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt4695080103656ac9/6628db9dfb977c54f236b2fa/nrl-16x9-compressed.mp4',
      },
      buttons: [
        {
          label: 'ดูรายละเอียด NLR',
          url: '/product/nlr',
          icon: 'arrow-forward',
          color: 'button-white',
        },
      ],
    },
    {
      preTitle: 'FRR MAX TORQUE',
      title: 'ใหม่! อีซูซุ FRR PERFECT PARTNER <span>ท้าทุกความสำเร็จ</span>',
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltffd6119339828d4f/61fa2f538624ed26d399c4b2/MAX_TORQUE_8SEC_mobile.jpg',
        alt: 'FRR MAX TORQUE',
        width: 608,
        height: 1080,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt626496642667ac66/61fa2f53bd7acb345ace6389/MAX_TORQUE_8SEC_-_desktop.jpg',
        alt: 'FRR MAX TORQUE',
        width: 1920,
        height: 1080,
      },
      video: {
        src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt6a0a9b5ace2d280a/61fa2f54ce30533458033f30/MAX_TORQUE_8SEC_mobile.mp4',
      },
      videoDesktop: {
        src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltcb6475c4a71ec72d/61fa2f546291fb2853f306ed/MAX_TORQUE_8SEC_-_desktop.mp4',
      },
      youtube: {
        label: 'ดูวิดีโอแบบเต็ม',
        url: '8gTDO49niw4',
        icon: 'play-round',
      },
      buttons: [
        {
          label: 'ดูรายละเอียด FRR',
          url: '/product/frr',
          icon: 'arrow-forward',
          color: 'button-white',
        },
      ],
    },
    {
      preTitle: 'FRR 210/190',
      title: 'ใหม่! อีซูซุ FRR PERFECT PARTNER <span>ท้าทุกความสำเร็จ</span>',
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltfa2b2dca0e1c608d/61fa4bbc2c429d35c030f525/N_L_8SEC_mobile.jpg',
        alt: 'FRR 210/190',
        width: 608,
        height: 1080,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt1e26d78ef55f2d55/61fa4bbd2f5ed026e153bb24/N_L_8SEC_-_desktop.jpg',
        alt: 'FRR 210/190',
        width: 1920,
        height: 1080,
      },
      video: {
        src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt018067f873f3a2d5/61fa442a2f5ed026e153bb1a/N_L_8SEC_-_1080X1920_1M.mp4',
      },
      videoDesktop: {
        src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt0e1a7fc42dc50c36/61fa442aa7cc8435c175e692/N_L_8SEC_-_1920X1080_1D.mp4',
      },
      youtube: {
        label: 'ดูวิดีโอแบบเต็ม',
        url: 'NIdu6TYZqZo',
        icon: 'play-round',
      },
      buttons: [
        {
          label: 'ดูรายละเอียด FRR',
          url: '/product/frr',
          icon: 'arrow-forward',
          color: 'button-white',
        },
      ],
    },
    {
      preTitle: 'GXZ 360',
      title: 'สุดยอดรถบรรทุกต้อง...<span>อีซูซุคิงออฟทรัคส์</span>',
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltb10e9235df076987/627ca449220ab5285f52eea3/GXZ-Banner-5-m.jpg',
        alt: 'GXZ 360',
        width: 1200,
        height: 1600,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt68ce7bb2491ffb3b/627ca444deee7329767fe3f4/GXZ-Banner-5-d.jpg',
        alt: 'GXZ 360',
        width: 2048,
        height: 1024,
      },
      buttons: [
        {
          label: 'รายละเอียดทางเทคนิค',
          url: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt83a24ef0a49e9612/649bae90b93cad11cea701fb/spec-sheet-all-gxz-2023.pdf',
          target: '_blank',
          icon: 'file-download',
          color: 'button-white',
        },
      ],
    },
    {
      preTitle: 'เทเลเทค',
      title: 'GPS อัจฉริยะ...<span>เพื่อโลจิสติกส์ยุคใหม่</span>',
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltcfbca71c7117d942/61f11f305874d72782979f31/banner-2-m.jpg',
        alt: 'Teletec',
        width: 1200,
        height: 1600,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt3e0522baa1f4d777/61f11f1bea9775298ed58541/banner-2-d.jpg',
        alt: 'Teletec',
        width: 2048,
        height: 1024,
      },
      youtube: {
        label: 'ดูวิดีโอแบบเต็ม',
        url: 'eVtFeKOOLNw',
        icon: 'play-round',
      },
      buttons: [
        {
          label: 'ดูรายละเอียด',
          url: '/teletec',
          icon: 'arrow-forward',
          color: 'button-white',
        },
      ],
    },
    {
      preTitle: 'อะไหล่แท้ตรีเพชร',
      title: 'คุณภาพที่คุณมั่นใจ',
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltf2c709bfcf3b79cb/61e8eb7c5e95b754d6ff32de/banner-7-m.jpg',
        alt: 'Parts',
        width: 1200,
        height: 1600,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blta75194577405b369/61e8eb6a5e95b754d6ff32dc/banner-7-d.jpg',
        alt: 'Parts',
        width: 2048,
        height: 1024,
      },
      buttons: [
        {
          label: 'ดูรายละเอียด ',
          url: '/lcs/parts',
          icon: 'arrow-forward',
          color: 'button-white',
        },
      ],
    },
    {
      preTitle: 'ขายรถบรรทุกของคุณ',
      title: 'ฟรี ประเมินราคา การจัดโอน <span>และลบโลโก้บนรถ</span>',
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt6bf398a8f8e0ffed/6284b3df0457495358471977/sellyourtruck-m.jpg',
        alt: 'Sell Your Truck',
        width: 1200,
        height: 1600,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltf665a98cdfce0a2f/6284b3df3b9b8554904a0da9/sellyourtruck-d.jpg',
        alt: 'Sell Your Truck',
        width: 2048,
        height: 1024,
      },
      buttons: [
        {
          label: 'สนใจขายรถบรรทุก',
          url: '/register/sell-your-truck',
          icon: 'arrow-forward',
          color: 'button-white',
        },
      ],
    },
  ],
};

export const BANNER_DATA: IMY24HeroBanner = {
  isCloseSplash: true,
  dataTrack: 'cv-home',
  headline: {
    title: 'รถบรรทุกอีซูซุประเทศไทย ISUZU TRUCKS Thailand Official',
  },
  items: [
    {
      title: 'ISUZU King of Trucks EURO 5 MAX <span>ขีดสุดทุกมิติขนส่ง</span>',
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blta1b7d9b253e8e7f5/666ba94d6e8ad08b601b78d1/e5-line-up-mobile.jpg',
        alt: 'ISUZU King of Trucks EURO 5 MAX',
        width: 600,
        height: 800,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt4d064ad10224e83b/66024e48db68ba74fe39e5fd/e5-all.jpg',
        alt: 'ISUZU King of Trucks EURO 5 MAX',
        width: 600,
        height: 338,
      },
      video: {
        src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt6648f6b4f55e21c2/667398f43ab7dbfd7318d6c6/line-up.mp4',
      },
      videoDesktop: {
        src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltc0a3904dd97baff8/666bae780c0c3e41b30375fa/e5-line-up-vdo.mp4',
      },
      youtube: {
        label: 'ดูวิดีโอแบบเต็ม',
        url: 'ojoAZkJ3Ln4',
        icon: 'play-round',
      },
      logo: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blta29d6e56170f7fa6/65fad39eedb2c7b1cf372f85/logo-euro-5-max.svg',
        alt: 'ISUZU King of Trucks EURO 5 MAX',
        width: 300,
        height: 72,
      },
      decorate: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt08dc9d3f71e092e2/65e9b70f9a29122204ce5826/butterfly.png',
        alt: 'Butterfly',
        width: 210,
        height: 234,
      },
      buttons: [
        {
          label: 'ค้นหาและเปรียบเทียบ',
          url: '/product',
          color: 'button-white',
          border: true,
        },
      ],
    },
    {
      title: 'ใหม่! อีซูซุ GXZ EURO 5 MAX <span>ขีดสุดทุกมิติขนส่ง</span>',
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltb8c7165871838352/666ba94e04c629764f6f21e1/e5-gxz-mobile.jpg',
        alt: 'GXZ EURO 5 MAX',
        width: 600,
        height: 800,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltdc02791fd5392a5f/66024e376f2eed2735c2f19e/e5-gxz.jpg',
        alt: 'GXZ EURO 5 MAX',
        width: 600,
        height: 338,
      },
      video: {
        src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltd4c0515dfd4934e9/667399180c0c3e67b103a708/gxz.mp4',
      },
      videoDesktop: {
        src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltac191c2daff5609b/66024e3851d67e62132422cc/e5-gxz-vdo.mp4',
      },
      logo: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt681bce99fb1251e7/65f90bbafc8631fe80d71c23/gxz.svg',
        alt: 'GXZ',
        width: 300,
        height: 72,
      },
      buttons: [
        {
          label: 'ดูรายละเอียด',
          url: '/product/gxz',
          color: 'button-white',
          border: true,
        },
      ],
    },
    {
      title: 'ใหม่! อีซูซุ​ FRR EURO 5 MAX <span>ขีดสุดทุกมิติขนส่ง</span>',
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt1904c25eceef8481/666ba94dfb43157454e6271b/e5-frr-mobile.jpg',
        alt: 'FRR EURO 5 MAX',
        width: 600,
        height: 800,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltea5d5215739cf800/66024e41bdfec3ca8b582e01/e5-frr.jpg',
        alt: 'FRR EURO 5 MAX',
        width: 600,
        height: 338,
      },
      video: {
        src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt04a21e636e8d04d6/667399363ff93c1669ab9ac7/frr.mp4',
      },
      videoDesktop: {
        src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt03ad74505cc4c4d3/66024e424cea199204b8552b/e5-frr-vdo.mp4',
      },
      logo: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltaaf8278cef545b4f/65f90bba55464dc0df0e09cf/frr.svg',
        alt: 'FRR',
        width: 300,
        height: 72,
      },
      buttons: [
        {
          label: 'ดูรายละเอียด',
          url: '/product/frr',
          color: 'button-white',
          border: true,
        },
      ],
    },
    {
      title: 'ใหม่! อีซูซุ NLR EURO 5 MAX <span>ขีดสุดทุกมิติขนส่ง</span>',
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt44a9564b48173819/666ba94e0c0c3e919d0375ec/e5-nlr-mobile.jpg',
        alt: 'NLR EURO 5 MAX',
        width: 600,
        height: 800,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt5e44e3eb5a50110b/66024e2f51d67e358e2422c8/e5-nlr.jpg',
        alt: 'NLR EURO 5 MAX',
        width: 600,
        height: 338,
      },
      video: {
        src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt3f8e1704a29b87e1/6673995d3ff93c1feeab9ac9/nlr.mp4',
      },
      videoDesktop: {
        src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt4eaed8c0663f4518/66024e303caa573f24cfc30b/e5-nlr-vdo.mp4',
      },
      logo: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt66eff5a4b39bc0fd/65f90bbaf4a4cff35a14f5c8/nlr.svg',
        alt: 'NLR',
        width: 300,
        height: 72,
      },
      buttons: [
        {
          label: 'ดูรายละเอียด',
          url: '/product/nlr',
          color: 'button-white',
          border: true,
        },
      ],
    },
    {
      title:
        'สนับสนุนผู้ใช้รถบรรทุก<span>ตลอดอายุการใช้งาน</span> <span>ISUZU Life Cycle Solutions</span>',
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt3a4dfe28a8cfd147/666ba94d46a037f38ea27b82/e5-lcs-mobile.jpg',
        alt: 'ISUZU Life Cycle Solutions',
        width: 600,
        height: 800,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt3435b98e9f10380b/66024e29d057550ab1000c8b/lcs.jpg',
        alt: 'ISUZU Life Cycle Solutions',
        width: 600,
        height: 338,
      },
      video: {
        src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt3a88c2ef83428178/6673997c3641c7239a138569/lcs.mp4',
      },
      videoDesktop: {
        src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltb028e4f4981bf89b/666baed23ab7db56f018a4a5/e5-lcs-vdo.mp4',
      },
      logo: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt6e5824e12b67399e/65f90bbad957606808506b31/lcs.svg',
        alt: 'ISUZU Life Cycle Solutions',
        width: 300,
        height: 72,
      },
      youtube: {
        label: 'ดูวิดีโอแบบเต็ม',
        url: 'Yr-cpiqWJkA',
        icon: 'play-round',
      },
      buttons: [
        {
          label: 'ดูรายละเอียด',
          url: '/?section=lcs',
          color: 'button-white',
          border: true,
        },
      ],
    },
  ],
};

export const CONCEPT_DATA: Euro5HomeConceptProps = {
  dataTrack: 'cv-home',
  dataTest: 'concept',
  headline: {
    title: 'ISUZU King of Trucks EURO5 MAX',
  },
  items: [
    {
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltc0d43937dfb552d7/65f7d98455464d42090e0168/e5-gxz-mobile.jpg',
        alt: 'GXZ',
        width: 600,
        height: 800,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt295d2c2376b7e600/65e708cf5fd4763b9774f167/gxz.jpg',
        alt: 'GXZ',
        width: 600,
        height: 338,
      },
      preTitle: 'ISUZU King of Trucks <span>EURO 5 MAX</span>',
      title: 'ขีดสุดแห่ง<span>เทคโนโลยีดีเซล</span>',
      description:
        'ด้วยความเชี่ยวชาญด้านเทคโนโลยีเครื่องยนต์ดีเซล <span>อีซูซุ</span>ได้พัฒนาให้ ISUZU King of Trucks <span>EURO 5 MAX</span> <strong>ทุกรุ่น!</strong> สามารถผ่านมาตรฐานไอเสีย<span>ยูโร 5</span> ได้ โดยไม่ต้องเติมน้ำยาบำบัดไอเสีย เช่น <span>AdBlue<sup>&reg;*</sup></span> หรือ น้ำยาอื่นๆ',
      remark:
        '<sup>*</sup>AdBlue<sup>&reg;</sup> is the registered trademark of the Verband der Automobilindustrie e. V. (VDA)',
      buttons: [
        {
          label: 'ค้นหาและเปรียบเทียบ',
          url: '/product',
          color: 'button-white',
        },
      ],
    },
    {
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt22b41492295512cb/65f7d971ba94f051627cf143/e5-frr-mobile.jpg',
        alt: 'FRR',
        width: 600,
        height: 800,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt1bd461573c2bc4f9/65e70c68ed466137937879b6/frr.jpg',
        alt: 'FRR',
        width: 600,
        height: 338,
      },
      preTitle: 'ISUZU King of Trucks <span>EURO 5 MAX</span>',
      title: 'ขีดสุดแห่ง<span>สมรรถนะ</span>',
      description:
        'ด้วยเอกลักษณ์แห่งเครื่องยนต์จาก<span>อีซูซุ</span> ที่ให้ประสิทธิภาพทั้งด้านกำลังและการประหยัดน้ำมัน<span>ที่เหนือชั้น</span> <span>แข็งแกร่ง</span> <span>ทนทาน</span> รองรับงานบรรทุกหนักได้อย่างมั่นใจ ตามแบบฉบับของ ISUZU King of Trucks <span>EURO 5 MAX</span>',
      buttons: [
        {
          label: 'ค้นหาและเปรียบเทียบ',
          url: '/product',
          color: 'button-white',
        },
      ],
    },
    {
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltbfaf65dc53d69cdd/65f7d99454369a70d5694ca3/e5-nlr-mobile.jpg',
        alt: 'NLR',
        width: 600,
        height: 800,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltff84f67fa9110062/65e70cabed37f63bb4b0fff4/nlr.jpg',
        alt: 'NLR',
        width: 600,
        height: 338,
      },
      preTitle: 'ISUZU King of Trucks <span>EURO 5 MAX</span>',
      title: 'ขีดสุดแห่ง<span>ความคุ้มค่า</span>',
      description:
        'ด้วยความพร้อมด้านโซลูชันที่ครบวงจร <span>อีซูซุ</span>พร้อมเคียงคู่<span>ผู้ประกอบการ</span>ขนส่ง ช่วยลดต้นทุน เพิ่มผลกำไร คุ้มค่าตลอดการใช้งาน ด้วย ISUZU Life Cycle Solutions',
      buttons: [
        {
          label: 'ดูรายละเอียด',
          url: '/?section=lcs',
          color: 'button-white',
        },
      ],
    },
  ],
};

export const LINEUP_EURO_5_DATA: IHomeLineUp = {
  engineType: 'euro-5',
  initialSlide: 14,
  items: MENU_EURO_5_LINEU_UP,
  bg: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltaf83235220d75403/65e994481ab2dc6ec897a634/bg-line-up-mobile.jpg',
    alt: 'Euro 5',
    width: 1600,
    height: 1200,
  },
  bgDesktop: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltb38c813ff3db74b4/65e99422504e03a0ccf1c1f6/bg-line-up.jpg',
    alt: 'Euro 5',
    width: 2880,
    height: 920,
  },
  cube: [
    {
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltbfff19abee4eb23d/65f10c450c744d764c7bf849/top.jpg',
        alt: 'Euro 5',
        width: 640,
        height: 640,
      },
      side: 'top',
    },
    {
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltd946a111fc6543e8/65f10c6454369acd186932f2/bottom.jpg',
        alt: 'Euro 5',
        width: 640,
        height: 640,
      },
      side: 'bottom',
    },
    {
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt4326c590a542831a/65f10c79611972e34f644a66/left.jpg',
        alt: 'Euro 5',
        width: 640,
        height: 640,
      },
      side: 'left',
    },
    {
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blte2d6203650e57067/65f10c8b5fa1c6708d4bbf09/right.jpg',
        alt: 'Euro 5',
        width: 640,
        height: 640,
      },
      side: 'right',
    },
    {
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt01e33987f1da3fb8/65f10ca1d957609f6f5049c3/front.jpg',
        alt: 'Euro 5',
        width: 640,
        height: 640,
      },
      side: 'front',
    },
    {
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt1e28769dc10b6086/65f10cb4f79101eaf3ef2ca1/back.jpg',
        alt: 'Euro 5',
        width: 640,
        height: 640,
      },
      side: 'back',
    },
  ],
  logo: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltcd85bf55849aa196/66ab5a9ceb20b4b3d02d1c0e/logo-isuzu-king-of-trucks-euro5-max.svg',
    alt: 'Euro 5',
    width: 208,
    height: 37,
  },
  logoDecorate: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt08dc9d3f71e092e2/65e9b70f9a29122204ce5826/butterfly.png',
    alt: 'Butterfly',
    width: 210,
    height: 234,
  },
};

export const LINEUP_DATA: IHomeLineUp = {
  engineType: 'euro-3',
  initialSlide: 16,
  items: MENU_EURO_3_LINE_UP,
  logo: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt4b3318058ea38c23/66ab5a9c2fce451dba6fece4/logo-euro3.svg',
    alt: 'Euro 3',
    width: 256,
    height: 59,
  },
};

export const LCS_DATA: LcsProps = {
  logo: {
    graphicTop: {
      url: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt233e62087612d623/65e8311f7ca220463f1e4f30/graphic-top.jpg',
      dimension: {
        width: 553,
        height: 169,
      },
    },
    graphicBottom: {
      url: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltcf6d2097db7386a3/65e8311f4ec10751af1a06fe/graphic-bottom.jpg',
      dimension: {
        width: 618,
        height: 214,
      },
    },
    wording: {
      url: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt3b0ac07690bf840b/65e06d12330e0071947f6e77/lcs-logo.svg',
      dimension: {
        width: 384,
        height: 104,
      },
    },
  },
  title: 'รู้หรือไม่ ?',
  desc: 'อีซูซุไม่ได้เชี่ยวชาญแค่เรื่องรถ แต่ยังเชี่ยวชาญด้านโซลูชัน สำหรับลูกค้า<span>อีซูซุ</span> ให้การจัดการรถบรรทุกเป็นเรื่องง่าย ด้วยผลิตภัณฑ์ และบริการต่างๆ ที่สนับสนุนผู้ใช้งานรถบรรทุกตลอดการใช้งาน',
  video: {
    youtubeId: 'Yr-cpiqWJkA',
    image: {
      url: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt4fb29805f2763a3c/65e7e4a28b9c6236fa46fbcf/video-thumb.jpg',
      dimension: {
        width: 601,
        height: 338,
      },
    },
  },
  tagline: {
    line1: 'ลดต้นทุน เพิ่มกำไร',
    line2: 'ตลอดการใช้งาน',
  },
  items: [
    {
      header: {
        title: 'ซื้อรถบรรทุก<span>อย่างคุ้มค่า</span>',
        desc: 'ซื้อรถบรรทุกอีซูซุแล้ว<span>คุ้มค่าอย่างไร ?</span>',
        logo: {
          url: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blta9e8a1ce65fe39c6/65ddfdf34487d02cdc3feaa5/group-1.jpg',
          dimension: {
            width: 120,
            height: 120,
          },
        },
      },
      items: [MENU_LCS_SERVICE_CENTER, MENU_LCS_LEASING, MENU_LCS_RBSC],
    },
    {
      header: {
        title: 'ลดต้นทุนตลอด<span>การใช้งาน</span>',
        desc: 'รู้หรือไม่.. <span>ใช้รถอีซูซุ</span> แล้ว <span>ลดต้นทุน</span> <span>กำไรเหลือ?</span>',
        logo: {
          url: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt98c4f65685182d08/65ddfdf372b3871e1322b1f6/group-2.jpg',
          dimension: {
            width: 120,
            height: 120,
          },
        },
      },
      items: [
        MENU_LCS_ILSC,
        MENU_LCS_TELETEC,
        MENU_LCS_PARTS,
        MENU_LCS_ISUZU_CARE,
      ],
    },
    {
      header: {
        title: 'บริการมาตรฐาน<span>ราคาประหยัด</span>',
        desc: 'จริงหรือไม่ ? มาตรฐานงานบริการอีซูซุทำให้รถพร้อมใช้งานตลอดเวลา',
        logo: {
          url: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt7607e583b2e12cb4/65ddfdf331aca1088a7ee8ae/group-3.jpg',
          dimension: {
            width: 120,
            height: 120,
          },
        },
      },
      items: [
        MENU_LCS_SERVICE_CENTER_2,
        MENU_LCS_PARTS_2,
        MENU_LCS_ISUZU_CARE_2,
        MENU_LCS_TRUCK_SERVICE_LINE,
      ],
    },
    {
      header: {
        title: 'ขายต่อง่าย<span>ได้ราคา</span>',
        desc: `
        ทำไมรถบรรทุกอีซูซุถึง ขายต่อง่าย ได้ราคา? ด้วยยอดขายรถบรรทุกอันดับ 1 ในประเทศไทย ทำให้รถบรรทุกอีซูซุ<br/>
        <span class="correct-icon">เป็นที่ต้องการในตลาด</span><br/>
        <span class="correct-icon">ราคาขายต่อไม่ตก</span><br/>
        <span class="correct-icon">อะไหล่หาง่าย</span>
        `,
        logo: {
          url: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt11551bb5ba43af8a/65ddfdf32568ef05436cae76/group-4.jpg',
          dimension: {
            width: 120,
            height: 120,
          },
        },
      },
      items: [MENU_LCS_TRUCK_2_HAND],
    },
  ],
};

export const TESTIMONIAL_DATA: TestimonialProps = {
  headline: {
    preTitle: 'เสียงตอบรับจากลูกค้า',
    title: 'ISUZU Life Cycle Solutions',
  },
  items: [
    {
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt41e358c1631b787a/65f3ed32611972659e645b16/testimonial-1.jpg',
        alt: 'คุณณิตถวุฒิ ศิริอนันต์ภัทร์',
        width: 600,
        height: 338,
      },
      description: `"<span>อีซูซุไม่ใช่แค่เข้ามาขายรถอย่างเดียว</span> <span>แต่เข้ามาช่วยพัฒนาบุคลากรในองค์กร</span> <span>พนักงานขับรถของเราขับรถประหยัด</span> และปลอดภัย<span>มากขึ้น</span> <span>อัตราการสิ้นเปลืองน้ำมันลดลง 10%</span> <span>อุบัติเหตุลดลง 50%</span> <span>ผมได้รับการดูแลที่ดีจากผู้จำหน่ายอีซูซุ</span> <span>ซึ่งไม่น่าจะหาได้จากที่ไหนอีกแล้วครับ</span>"`,
      author: 'คุณณิตถวุฒิ ศิริอนันต์ภัทร์',
      position: 'กรรมการผู้จัดการ บริษัท แสงศิริเคมีเกษตร จำกัด',
    },
    {
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt64b4870a06d1f90b/65f3ed8b39973e01cc6e8342/testimonial-2.jpg',
        alt: 'คุณดุจชพงษ์ หงษ์ระนัย',
        width: 600,
        height: 338,
      },
      description: `"<span>ผมเคยไปลองซื้อรถบรรทุกแบรนด์อื่น</span><span>มาลองใช้</span> <span>โดยภาพรวม</span><span>ผมก็ยังมองว่า Isuzu ดีสุด</span>  <span>1.ประหยัดน้ำมัน</span> <span>2.ศูนย์บริการครอบคลุม</span> <span>3.ขายต่อง่าย ราคาไม่ตก</span> <span>ราคาขายต่อสูงกว่า</span> <span>ยี่ห้อที่ได้ลองใช้ 100,000-200,000 บาท</span>  <span>รถปีเดียวกัน</span> <span>สภาพใกล้เคียงกัน</span> <span>ถ้าจะต้องซื้อคันต่อไป</span> <span>คงเป็น ISUZU แล้วละครับ...</span>"`,
      author: 'คุณดุจชพงษ์ หงษ์ระนัย',
      position: 'กรรมการ บริษัท กันต์กวี จำกัด',
    },
    {
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt5d023225d26c13b0/65f3edabf4a4cff92514e305/testimonial-3.jpg',
        alt: 'คุณธนากร เจริญวงศา',
        width: 600,
        height: 338,
      },
      description: `<span>"การซื้อรถของเราไม่ได้มองแค่เพียงเรื่องราคา</span> <span>เราคาดหวังการดูแลตลอดการใช้งาน</span> <span>และเรื่องการควบคุมต้นทุน</span> <span>ซึ่งอีซูซุสามารถตอบโจทย์เราได้ทุกอย่าง</span> <span>คาดว่าจะคืนทุนในระยะเวลา 5 ปี</span> <span>ซื้ออีซูซุก็ไม่กังวลเรื่องการหาอะไหล่ด้วย</span> <span>มั่นใจว่าจะใช้รถไปได้นานๆ แน่นอน"</span>`,
      author: 'คุณธนากร เจริญวงศา',
      position: 'รองประธานเจ้าหน้าที่บริหาร บริษัท ทีเอสทีอี จำกัด (มหาชน)',
    },
  ],
};
